import React from 'react'
import { Navbar, Footer } from '../components'
import { Box, Container, Typography } from '@mui/material'
import { about } from '../data'

const About = () => {
  return (
    <>
      <Box sx={{ backgroundImage: 'linear-gradient(to right, #3B8DEB, #B175F8)' }} >
        <Navbar />
      </Box>
      <Container>
        <Typography variant='h4'>
          About
        </Typography>
        {
          about.map((point) => (
            <Box sx={{ textAlign: 'justify' }}>
              <Typography variant='h5'>{point.head}</Typography>
              <Typography variant='p'>{point.content}</Typography>
            </Box>
          ))
        } 
      </Container>
      <Footer />
    </>
  )
}

export default About