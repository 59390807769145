import React, { useState } from 'react'
import { Navbar, Footer } from '../components'
import { Box, Button, Container, Typography, TextField, Grid } from '@mui/material'
// import { send } from '@sendgrid/mail';
import { contactImage } from '../assets'

const Contact = () => {

  const KEY = process.env.REACT_APP_SENDGRID_KEY;

  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormState((prevState) => ({ ...prevState, [id]: value }));
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
   
  };

  return (
    <>
      <Box sx={{ backgroundImage: 'linear-gradient(to right, #3B8DEB, #B175F8)' }} >
        <Navbar />
      </Box>
      <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',textAlign: 'center', my: 3 }}>
        <Typography variant='h4'>
          We'd love to hear from You
        </Typography>
        <Typography variant='p'>
          Whether you are curious about features, stuck somewhere or having any questions - we're ready to answer any queries.
        </Typography>
        <Grid container spacing={2} sx={{ my: 4 }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
           <Box sx={{
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              marginRight: '1rem',
              '& img': {
                objectFit: 'contain',
                maxWidth: '90%',
              },
            }} >
              <img src={contactImage} alt="Contact" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant='h5'>
                Leave a Message
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: { xs: '100%', md: '80%' }, textAlign: 'center' }}>
              <TextField
                id='name'
                label='Name'
                variant='outlined'
                value={formState.name}
                onChange={handleInputChange}
                sx={{ marginBottom: '1rem', borderRadius: '25px', '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#B175F8' } } }}
              />
              <TextField
                id='email'
                label='Email'
                variant='outlined'
                value={formState.email}
                onChange={handleInputChange}
                sx={{ marginBottom: '1rem', borderRadius: '25px', '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#B175F8' } } }}
              />
              <TextField
                id='message'
                label='Message'
                multiline
                rows={4}
                variant='outlined'
                value={formState.message}
                onChange={handleInputChange}
                sx={{ marginBottom: '1rem', borderRadius: '25px', '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#B175F8' } } }}
              />
                <Button
                  size='large'
                  sx={{ backgroundImage: 'linear-gradient(to right, #3B8DEB, #B175F8)', color: '#fff', fontStyle: 'bold', px: 4, mx: 'auto', marginBottom: '1rem' }}
                >
                  Send
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Typography variant='p'>
          For more information, drop a mail to <a href="mailto:support@fienhance.com">support@fienhance.com</a>, or visit our website <a href="https://www.devsvilla.com" target="_blank">DevsVilla</a>.
        </Typography>
      </Container>
      <Footer />
    </>
  )
}

export default Contact