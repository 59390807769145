import React from 'react';
import { Container, Box, Typography, Grid, List, ListItem, ListItemText, ListItemAvatar } from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { useTheme } from '@mui/material/styles';

import { Navbar, Footer, SipTaxCalculator, Mobile } from '../../components';
import { sipCalculatorContent, benefits, features } from '../../data';
import { sipTaxCalculatorImage } from '../../assets';

const SipTaxCalculatorPage = () => {

  const theme = useTheme();

  return (
    <>
    <Box sx={{ height: '100%', backgroundImage: 'linear-gradient(to right, #3B8DEB, #B175F8)', display: 'flex', flexDirection: 'column', paddingBottom: 6 }}>
      <Navbar />
      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <SipTaxCalculator />
      </Box>
    </Box>
    <Container sx={{ marginTop: { xs: '2rem', md: '4rem', lg: '3rem' } }}>
      <Typography variant='h4'>
        <span style={theme.typography.gradientText}>SIP Tax Calculator</span>
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ textAlign: 'justify' }}>
          <Typography variant='p'>{sipCalculatorContent.text}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box
          component='img'
          src={sipTaxCalculatorImage}
          alt='tax Image'
          height='270px'
        />
        </Grid>
      </Grid>

      <Typography variant='h4'>
        Benefits of using <span style={theme.typography.gradientText}>SIP Tax Calculator</span>
      </Typography>
      <Typography variant='p'>
      {benefits.headerNote}
      <List>
        {benefits.points.map((point) => (
          <ListItem>
            <ListItemAvatar sx={{ alignSelf: 'flex-start', marginTop: '3px' }}>
              <DoubleArrowIcon />
            </ListItemAvatar>
            <ListItemText primary={point} />
          </ListItem>
        ))}
      </List>
      {benefits.footerNote}
      </Typography>
    </Container>

    <section id="mobile">
      <Mobile />
    </section>

    <Container>
      <Typography variant='h4' sx={{ marginTop: '4rem' }}>
        Features of <span style={theme.typography.gradientText}>SIP Tax Calculator</span>
      </Typography>
      <Typography variant='p'>
      {features.headerNote}
      <List>
        {features.points.map((point) => (
          <ListItem>
            <ListItemAvatar sx={{ alignSelf: 'flex-start', marginTop: '3px' }}>
              <DoubleArrowIcon />
            </ListItemAvatar>
            <ListItemText primary={point} />
          </ListItem>
        ))}
      </List>
      {features.footerNote}
      </Typography>
    </Container>
    
    <Footer />
    </>
  )
}

export default SipTaxCalculatorPage