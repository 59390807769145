import { Box, Container, Grid, Typography, Link } from '@mui/material';
import { fienhanceLogo } from '../assets';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

const Footer = () => {
  return (
    <Box sx={{ backgroundImage: 'linear-gradient(to right, #3B8DEB, #B175F8)', py: 5, marginTop: 4 }}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', md: 'flex-start' } }}>
            <Box onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                backgroundColor: '#fff', 
                marginRight: '1rem',
                height: '50px',
                width: '50px',
                borderRadius: '12px',
                '& img': {
                  objectFit: 'contain',
                  maxWidth: '70%',
                },
              }}>
                <img src={fienhanceLogo} alt="Fi-Enhance logo" />
              </Box>
              <Box>
                <Typography variant="h5" sx={{ color: '#fff', fontWeight: 'bold' }}>Fi-Enhance</Typography>
              </Box>
            </Box>
            <Typography variant="h6" sx={{ color: '#fff', my: 3 }}>Plan. Calculate. Save.</Typography>
            <Typography variant="body1" sx={{ color: '#fff' }}>Your financial toolkit at your fingertips</Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" sx={{ color: '#fff', mb: '1rem' }}>Calculators</Typography>
              <Typography variant="body1" sx={{ color: '#fff', mb: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                <Link href="/sip-tax-calculator" sx={{ color: '#fff', mb: 1, display: 'flex', alignItems: 'center' }}>
                  <KeyboardArrowRightRoundedIcon sx={{ color: '#fff' }} />
                  SIP Tax Calculator
                </Link>
                <Link href="/sip-returns-calculator" sx={{ color: '#fff', mb: 1, display: 'flex', alignItems: 'center' }}>
                  <KeyboardArrowRightRoundedIcon sx={{ color: '#fff' }} />
                  SIP Returns Calculator
                </Link>
                <Link href="/fd-returns-calculator" sx={{ color: '#fff', mb: 1, display: 'flex', alignItems: 'center' }}>
                  <KeyboardArrowRightRoundedIcon sx={{  color: '#fff' }} />
                  FD Returns Calculator
                </Link>
                <Link href="/lumpsum-calculator" sx={{ color: '#fff', display: 'flex', alignItems: 'center' }}>
                  <KeyboardArrowRightRoundedIcon sx={{  color: '#fff' }} />
                  Lumpsum Calculator
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" sx={{ color: '#fff', mb: '1rem', }}>Quick Links</Typography>
              <Typography variant="body1" sx={{ color: '#fff', mb: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                <Link href="/" sx={{ color: '#fff', mb: 1, display: 'flex', alignItems: 'center' }}>
                  <KeyboardArrowRightRoundedIcon sx={{ color: '#fff' }} />
                  Home
                </Link>
                <Link href="/contact" sx={{ color: '#fff', mb: 1, display: 'flex', alignItems: 'center' }}>
                  <KeyboardArrowRightRoundedIcon sx={{ color: '#fff' }} />
                  Contact
                </Link>
                <Link href="/about" sx={{ color: '#fff', mb: 1, display: 'flex', alignItems: 'center' }}>
                  <KeyboardArrowRightRoundedIcon sx={{ color: '#fff' }} />
                  About
                </Link>
                <Link href="/terms" sx={{ color: '#fff', mb: 1, display: 'flex', alignItems: 'center' }}>
                  <KeyboardArrowRightRoundedIcon sx={{ color: '#fff' }} />
                  Terms and Conditions
                </Link>
                <Link href="/policy" sx={{ color: '#fff', display: 'flex', alignItems: 'center' }}>
                  <KeyboardArrowRightRoundedIcon sx={{ color: '#fff' }} />
                  Privacy Policy
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Typography align='center' sx={{ color: '#fff', mt: 3, fontSize: '11px' }}>
          All Rights Reserved ©2023. DevsVilla International
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
