import React from 'react';
import { Box, Typography } from '@mui/material';

const CalculatorCard = ({ image, name, comingSoon }) => {
  const [isHovering, setIsHovering] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        backgroundColor: 'rgba(59, 141, 235, 0.8)',
        backgroundImage: 'linear-gradient(to right, #3B8DEB, #B175F8)',
        borderRadius: '25px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        overflow: 'hidden',
        cursor: 'pointer',
        '& img': {
          objectFit: 'contain',
          maxWidth: '100%',
          transition: 'transform 0.3s',
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img
        src={image}
        alt="Calculator Thumbnail"
        style={{ transform: isHovering ? 'translateY(-100%)' : 'translateY(0)' }}
      />
      <Typography
        variant="h6"
        sx={{
          position: 'absolute',
          bottom: isHovering ? '50%' : '24px',
          left: '50%',
          transform: 'translate(-50%, 50%)',
          padding: '8px',
          backgroundColor: 'rgba(59, 141, 235, 0.8)',
          backgroundImage: 'linear-gradient(to right, #3B8DEB, #B175F8)',
          color: '#fff',
          fontWeight: 'bold',
          textAlign: 'center',
          width: '100%',
          transition: 'bottom 0.3s',
        }}
      >
        {name}
        { isHovering && (<Typography
            variant="h6"
            sx={{
              fontWeight: 'normal',
              textAlign: 'center',
              fontSize: '1.1rem'
            }}
          >
            {comingSoon && 'Coming Soon'}
          </Typography>
        )}
      </Typography>
    </Box>
  );
};

export default CalculatorCard;
