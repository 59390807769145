import React from 'react'
import { VictoryPie } from 'victory';
import { Box } from '@mui/material';

const DonutChart = ({data}) => {
  return (
    <Box height={250}>
        <VictoryPie
          data={data}
          innerRadius={90}
          padAngle={0}
          colorScale={['#B175F8', '#51A2FF']}
          labels={() => ''}
          padding={10}
        />
    </Box>
  )
}

export default DonutChart