export const formatCurrency = (num) => {
  return num.toLocaleString('en-IN', {style: 'currency', currency: 'INR'});
};
  
export const formatCurrencyNoDecmimal = (num) => {
  return num.toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};