import { Container, Grid, Slider, Typography, TextField, Box, Checkbox, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'

const SipTaxCalculator = () => {

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [gains, setGains] = useState([
    { name: 'Equity Gains - Long Term', value: 200000, taxRate: 0.1 },
    { name: 'Equity Gains - Short Term', value: 50000, taxRate: 0.15 },
    { name: 'Debt Gains - Long Term', value: 100000, taxRate: 0.2 },
    { name: 'Debt Gains - Short Term', value: 25000, taxRate: 0.3 },
  ]);

  const [isELSS, setIsELSS] = useState(true);
  const [ELSSGain, setELSSGain] = useState(0);

  const convertNaNToZero = value => isNaN(value) ? 0 : value;
  const formatDecimal = (number) => number.toFixed(2);

  const handleSliderChange = (index) => (event, newValue) => {
    setGains((prevGains) => {
      const updatedGains = [...prevGains];
      updatedGains[index].value = newValue;
      return updatedGains;
    });
  };

  const handleTextFieldChange = (index) => (event) => {
    let newValue = parseInt(event.target.value);
    if (newValue < 0) {
      newValue = 0;
    } else if (newValue > 500000) {
      newValue = 500000;
    }
    if(event.target.name === 'ELSSGain') {
      setELSSGain(newValue);
    } else {
      setGains((prevGains) => {
        const updatedGains = [...prevGains];
        updatedGains[index].value = newValue;
        return updatedGains;
      });
    }
  };

  const handleCheckBoxChange = () => {
    setIsELSS(!isELSS);
    setELSSGain(0);
  }

  const calculateTaxNew = () => {
    let totalTax = 0;
    gains.forEach(({ name, value, taxRate }) => {
      if(name === 'Equity Gains - Long Term') {
        let taxableAmount = convertNaNToZero(value);
        let elssGain = convertNaNToZero(ELSSGain)
        if(isELSS && elssGain > 100000) {
          taxableAmount -= 100000; 
        } else {
          taxableAmount -= elssGain;
        }
        totalTax += taxableAmount * 0.1;
      } else {
      totalTax += convertNaNToZero(value) * taxRate;
      }
    });
    const finalTax = totalTax * 0.04;
    return formatDecimal(totalTax + finalTax);
  };
  
  console.log(calculateTaxNew());

  return (
    <Container
      sx={{
        backgroundColor: '#fff',
        // height: { xs: '90%', sm: '90%', md: '80%', lg: '70%' },
        width: { xs: '90%', sm: '90%', md: '80%', lg: '70%' },
        borderRadius: '25px',
        marginTop: '1rem',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        paddingY: { xs: 2, md: 8 }
      }}
    >
      <Grid container spacing={{ xs: 1, sm: 1, md: 4, lg: 4 }} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
        {gains.map(({ name, value }, index) => (
          <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '1rem'
              }}
            >
              <Typography variant="p">{name}</Typography>
              {
                name === 'Equity Gains - Long Term' && ( 
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      checked={isELSS}
                      onChange={handleCheckBoxChange}
                      color="primary"
                    />
                    {!isELSS && (
                      <Typography variant="body2">
                        ELSS
                      </Typography>
                    )}
                    {
                      isELSS && (
                        <TextField
                          name='ELSSGain'
                          label='ELSS'
                          size="small"
                          type="number"
                          value={ELSSGain}
                          onChange={handleTextFieldChange()}
                          sx={{ background: 'linear-gradient(to right, rgb(59 141 235 / 10%), rgb(177 117 248 / 10%))', border: 'none',borderRadius: '5px', textAlign: 'center' }}
                          inputProps={{
                            min: 0,
                            max: 100000,
                            step: 1,
                          }}
                          InputProps={{ 
                            style: { color: "#B175F8" }
                          }}
                          InputLabelProps={{
                            style: { color: '#000000' }
                          }}
                        />   
                      )
                    }
                  </Box>
                )
              }
              <TextField
                size="small"
                type="number"
                value={value}
                onChange={handleTextFieldChange(index)}
                sx={{ background: 'linear-gradient(to right, rgb(59 141 235 / 10%), rgb(177 117 248 / 10%))', border: 'none',borderRadius: '5px', textAlign: 'center' }}
                inputProps={{
                  min: 0,
                  max: 500000,
                  step: 1,
                }}
                InputProps={{ 
                  style: { color: "#B175F8" }
                }}
              />
            </Box>
            <Slider
              value={convertNaNToZero(value)}
              onChange={handleSliderChange(index)}
              min={0}
              max={500000}
              step={500}
              aria-labelledby="input-slider"
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Typography variant={isMobile ? 'h3' : 'h2'} sx={{ textAlign: 'center', color: '#212933' }}>
            Total Tax:  <span style={{ background: 'linear-gradient(to right, #3B8DEB, #B175F8)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>&#8377;{calculateTaxNew()}</span>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SipTaxCalculator;
