import React, {useState} from 'react';
import { AppBar, Container, Button, Typography, Box, Toolbar, Menu, MenuItem, ListItemText, Collapse, List, ListItem } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { fienhanceLogo } from '../assets';

const Navbar = ({ routeLink }) => {
  const [calculatorAnchorEl, setCalculatorAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [calculatorOpen, setCalculatorOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const scrollHandler = (event) => {
    event.preventDefault();
    const mobileSection = document.getElementById("mobile");
    if (mobileSection) {
      const scrollOptions = {
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      };
  
      const delay = 100; // Delay in milliseconds before scrolling starts
  
      setTimeout(() => {
        mobileSection.scrollIntoView(scrollOptions);
      }, delay);
    }
  };
  

  const getTitle = () => {
    switch (location.pathname) {
      case '/':
        return 'Fi-Enhance';
      case '/sip-tax-calculator':
        return 'SIP Tax Calculator';
      case '/sip-returns-calculator':
        return 'SIP Returns Calculator';
      case '/fd-returns-calculator':
        return 'FD Returns Calculator';
      case '/lumpsum-calculator':
        return 'Lumpsum Calculator';
      default:
        return 'Fi-Enhance';
    }
  };

  const handleCalculatorOpen = (event) => {
    setCalculatorAnchorEl(event.currentTarget);
  };

  const handleCalculatorClose = () => {
    setCalculatorAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleCalculatorClick = () => {
    setCalculatorOpen(!calculatorOpen);
  };

  const handleDownloadClick = () => {
    navigate('/#mobile');
  };


  return (
    <Container>
      <AppBar position="static" sx={{ background: 'transparent', boxShadow: 'none', }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
         <Link to='/'>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              backgroundColor: '#fff', 
              marginRight: '1rem',
              height: '50px',
              width: '50px',
              borderRadius: '12px',
              '& img': {
                objectFit: 'contain',
                maxWidth: '70%',
              },
            }}>
              <img src={fienhanceLogo} alt="Fi-Enhance logo" />
            </Box>
          </Link>
          <Typography variant="h4" sx={{ flexGrow: 1, color: '#fff', fontSize: { xs: '1.5rem', md: '2rem' } }}>
            {getTitle()}
          </Typography>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: '1rem' }}>
            <Button color="inherit" href="/">Home</Button>
            <Button color="inherit" aria-controls="calculator-menu" aria-haspopup="true" onClick={handleCalculatorOpen}>
              Calculator
            </Button>
            <Menu
              id="calculator-menu"
              anchorEl={calculatorAnchorEl}
              open={Boolean(calculatorAnchorEl)}
              onClose={handleCalculatorClose}
            >
              <MenuItem onClick={handleCalculatorClose} component={Link} to="/sip-tax-calculator">SIP Tax Calculator</MenuItem>
              <MenuItem onClick={handleCalculatorClose} component={Link} to="/sip-returns-calculator">SIP Returns Calculator</MenuItem>
              <MenuItem onClick={handleCalculatorClose} component={Link} to="/fd-returns-calculator">FD Returns Calculator</MenuItem>
              <MenuItem onClick={handleCalculatorClose} component={Link} to="/lumpsum-calculator">Lumpsum Calculator</MenuItem>
            </Menu>
            <Button color="inherit" href="/contact">Contact</Button>
            <Button color="inherit" href="/about">About</Button>
            <Button color="inherit" variant="outlined" >
              <a href="#mobile" onClick={(event) => scrollHandler(event)} style={{ color: '#fff', textDecoration: 'none' }}>
                Download
              </a>
            </Button>
          </Box>
          <MenuRoundedIcon onClick={handleMenuClick} sx={{ color: '#fff', fontStyle: 'bold', fontSize: '2.5rem',  display: { xs: 'flex', md: 'none' }}} />
            <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleMenuClose}>
             <MenuItem onClick={handleMenuClose} component={Link} to="/" ><ListItemText primary="Home" /></MenuItem>
             <MenuItem onClick={handleCalculatorClick}>
               <ListItemText primary="Calculator" />
               {calculatorOpen ? <ExpandLess /> : <ExpandMore />}
             </MenuItem>
             <Collapse in={calculatorOpen} timeout="auto" unmountOnExit>
               <List component="div" disablePadding>
                 <ListItem sx={{ pl: 4 }} component={Link} to="/sip-tax-calculator">
                   <ListItemText primary="SIP Tax Calculator" />
                 </ListItem>
                 <ListItem sx={{ pl: 4 }} component={Link} to="/sip-returns-calculator">
                   <ListItemText primary="SIP Returns Calculator" />
                 </ListItem>
                 <ListItem sx={{ pl: 4 }} component={Link} to="/fd-returns-calculator">
                   <ListItemText primary="FD Returns Calculator" />
                 </ListItem>
                 <ListItem sx={{ pl: 4 }} component={Link} to="/lumpsum-calculator">
                   <ListItemText primary="Lumpsum Calculator" />
                 </ListItem>
               </List>
             </Collapse>
             <MenuItem onClick={handleMenuClose} component={Link} to="/contact"><ListItemText primary="Contact" /></MenuItem>
             <MenuItem onClick={handleMenuClose} component={Link} to="/about"><ListItemText primary="About" /></MenuItem>
            </Menu>
        </Toolbar>
      </AppBar>
    </Container>
  );
};

export default Navbar;
