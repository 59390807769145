import React from 'react'
import { Box, Container, Typography, List, ListItem, ListItemText, ListItemAvatar } from '@mui/material'
import { Navbar, Footer } from '../components'
import { termsAndConditions } from '../data'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

const Terms = () => {
  return (
    <>
      <Box sx={{ backgroundImage: 'linear-gradient(to right, #3B8DEB, #B175F8)' }} >
        <Navbar />
      </Box>
      <Container>
        <Typography variant='h4'>Terms and Conditions</Typography>
        <List>
          {termsAndConditions.points.map((point) => (
            <ListItem sx={{ color: '#5F6671'  }}>
              <ListItemAvatar sx={{ alignSelf: 'flex-start', marginTop: '3px' }}>
                <DoubleArrowIcon />
              </ListItemAvatar>
              <ListItemText primary={point} />
            </ListItem>
          ))}
        </List>
      </Container>
      <Footer />
    </>
  )
}

export default Terms