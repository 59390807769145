import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
      primary: {
        main: '#3B8DEB'
      },
      secondary: {
        main: '#B175F8'
      },
      color: {
        main: '#212933',
        white: '#ffffff',
      },
      gradient: {
        primary: 'linear-gradient(to right, #3B8DEB, #B175F8)',
      },
    },
    typography: {
      fontFamily: 'Roboto, Outfit',
      h1: {
        fontWeight: 'bold',
        color: '#fff',
        fontFamily: 'Outfit'
      },
      h2: {
        fontWeight: 'bold',
        color: '#fff',
        fontFamily: 'Outfit'
      },
      h3: {
        fontWeight: 'bold',
        color: '#fff',
        fontFamily: 'Outfit'
      },
      h4: {
        margin: '2rem 0',
        fontWeight: 'bold',
        color: '#212933',
        fontFamily: 'Outfit'
      },
      h5: {
        margin: '1rem 0',
        color: '#212933',
        fontFamily: 'Outfit',
        fontWeight: 'bold',
      },
      h6: {
        fontFamily: 'Outfit',
        fontWeight: 'bold',
      },
      p: {
        color: '#5F6671',
        fontFamily: 'Roboto',
        lineHeight: '1.5',
      },
      body2: {
        fontFamily: 'Roboto',
        color: '#3B8DEB',
      },
      gradientText: {
        background: 'linear-gradient(to right, #3B8DEB, #B175F8)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      },
    },
    components: {
      MuiSlider: {
        styleOverrides: {
          root: {
            color: '#B175F8',
          },
          thumb: {
            color: '#B175F8',
          },
          track: {
            color: '#B175F8',
          },
          rail: {
            color: '#3B8DEB',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '20px',
            fontFamily: 'Outfit',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          input: {
            color: '#3B8DEB',
          }
        }
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: '#3B8DEB'
          }
        }
      }
    }
  });

  export default theme;