import './App.css';
import { Home, Contact, About, Terms, Policy, SipTaxCalculatorPage, SipReturnsCalculatorPage, FDReturnsCalculatorPage, LumpsumCalculatorPage } from './pages';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sip-tax-calculator" element={<SipTaxCalculatorPage />} />
          <Route path="/sip-returns-calculator" element={<SipReturnsCalculatorPage />} />
          <Route path="/fd-returns-calculator" element={<FDReturnsCalculatorPage />} />
          <Route path="/lumpsum-calculator" element={<LumpsumCalculatorPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/policy" element={<Policy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
