export const fiEnhanceContent = {
    headerNote: 'Fi-Enhance - a web application that simplifies your financial planning and helps you make informed decisions about your investments by providing a range of financial calculators.',
    points: [
        'Our mission is to simplify finance and save valuable time and money for millions of individuals and businesses across India.',
        'Our web application provides a range of tools and calculators to help you with your financial planning.',
        'We are commited to provide you resources which can allow you to make informed decisions about investments and plan finances with ease.',
        'Plan your investments, Calculate your taxes and maximize your gains',
    ],
    footerNote: 'Try Fi-Enhance today and take control of your finances!',    
}

export const sipCalculatorContent = {
    text: 'SIP Tax Calculator for Mutual Fund is a powerful tool designed to assist investors in calculating taxes on their investment profits. It\'s a common challenge for investors to calculate the correct tax amount for each of their investments, especially when they have multiple SIPs across various mobile applications. With this calculator however, the process becomes effortless and much more manageable.The web application is specifically designed to simplify the tax calculation process for users, particularly those with multiple investments. It\'s optimized to provide accurate tax calculations for different types of SIPs, including Equity Gains - Short Term, Equity Gains - Long Term, Debt Gains - Short Term, and Debt Gains - Long Term.'
}

export const benefits = {
    headerNote: 'The SIP Tax Calculator is a valuable tool that saves investors a lot of time and effort when calculating taxes on their investments.',
    points: [
        "Simple and convenient to use.",
        "Helps plan your investment better.",
        "Saves you the trouble of performing complex and lengthy tax calculations.",
        "Users can save or print the summary and tax calculations for their records, making it easy to track investments and taxes.",
        "The tax calculator web app makes it easy for users to enter investment details, including investment amount against each type of SIP.",
    ],
    footerNote: 'Overall, the tax calculator web app provides a simple and efficient solution for managing investment portfolios and staying on top of tax obligations, making it a valuable tool for any investor.' 
}

export const features = {
    headerNote: 'The tax calculator web app has been designed to make it easy for users to enter the details of their investments. This includes the investment amount against each type of Systematic Investment Plan (SIP). SIPs are a type of investment plan in which an investor invests a fixed amount of money at regular intervals.',
    points: [
        "The app provides a user interface with input fields or forms for the user to fill out investment details.",
        "Users can enter the investment amount for each SIP, along with other relevant details required by the app.",
        "Users can save or print the summary and tax calculations for their records to track investments and tax obligations.",
        "Users can view the calculated tax amount, along with a breakdown of the tax amount for each type of SIP.",
        "The app generates a summary of the investment details and tax calculations, which includes investment amount, current value, profit gained from each SIP, and total profit gained from all SIPs combined.",
    ],
    footerNote: 'The app calculates tax on profit gains for each type of SIP and the total tax amount based on current tax laws and regulations.' 
}

export const sipReturnsCalculator = {
    about: 'A SIP returns calculator is a tool used to estimate the potential returns or growth of an investment made through a SIP. SIP is a popular investment method where an individual invests a fixed amount of money regularly at predetermined intervals (such as monthly or quarterly) into a mutual fund or other investment instruments. The SIP returns calculator takes into account factors such as the investment amount, investment duration, expected rate of return, and the frequency of investments to provide an estimate of the future value of the investment. It helps investors get an idea of the potential growth of their investment over time.',
    howTo: [
        'Investment Amount: The amount you plan to invest regularly at each interval (e.g., monthly SIP amount).',
        'Investment Duration: The time period over which you plan to make regular investments (e.g., number of years or months).',
        'Expected Rate of Return: The average annual rate of return you anticipate from the investment. This can be based on historical performance or future expectations.',
        'Calculate the amount: Once you have entered all the required details, the SIP Returns calculator will provide you with the return amount. This amount represents the total value of your estimated return amount you can avail after your investment tenure is complete.'
    ],
    benefits: [
        'Financial Planning: The SIP returns calculator allows you to plan your investments effectively by providing you with an estimate of the potential returns. You can input different investment amounts, durations, and expected rates of return to analyze various scenarios and choose the one that aligns with your financial goals.',
        'Decision making: With the help of the SIP returns calculator, you can make well-informed investment decisions. By understanding the potential returns based on different parameters, you can adjust your investment strategy accordingly.',
        'Comparisons and Analysis: The calculator allows you to compare different investment scenarios. By altering the investment amount, duration, or expected rate of return, you can analyze the impact on potential returns. This helps you evaluate various investment options and choose the one that best suits your requirements.',
    ]
}

export const fdReturnsCalculator = {
    about: 'An FD calculator simplifies the process of calculating the maturity amount of a fixed deposit. It saves you time and effort by swiftly providing accurate figures with just a click. The complexity of FD maturity calculations involves various variables, with an online fienhance FD calculator, you don\'t have to worry about performing intricate computations manually. It does the heavy lifting for you. Instead of manually crunching numbers, you can rely on the calculator to quickly generate precise results. Moreover, an FD calculator allows you to compare the maturity amount and interest rates offered by different financial institutions. This empowers you to make an informed decision by having all the necessary figures readily available.',
    howTo: [
        'Determine the details of your fixed deposit: Gather the necessary information about your fixed deposit, including the principal amount (initial investment), the interest rate, and the tenure.',
        'Input the details: Enter the required information into the calculator. Typically, you will need to provide the following details: Principal Amount, Rate of Interest and Time period.',
        'Calculate the maturity amount: Once you have entered all the required details, the FD calculator will provide you with the maturity amount. This amount represents the total value of your fixed deposit, including the interest earned at the end of the tenure.'
    ],
    benefits: [
        'Take advantage of our free FD Returns Calculator to obtain an accurate estimation of your fixed deposit\'s maturity amount.',
        'Quickly and conveniently compare the maturity amounts from multiple financial institutions.',
        'Determine the exact maturity amount of your fixed deposit and plan your future accordingly.',
        'This convenient tool saves you time and effort by automating the process, allowing you to obtain precise results without exerting extensive mental effort.',
        'By using the calculator, you can conveniently assess the potential outcomes of various FD options and choose the one that best suits your needs.',
    ]
}

export const lumpsumCalculator = {
    about: 'A lump sum calculator is a tool used to estimate the future value of a sum of money considering factors like the initial amount, interest rate, and time period. It assists in financial planning and investment analysis. By inputting these variables, the calculator computes the potential growth or value of the lump sum over time. This helps individuals make informed decisions regarding their investments and financial goals. Whether saving for a down payment, retirement, or other long-term objectives, the lump sum calculator provides valuable insights into the potential outcome of a one-time investment. The lump sum calculator allows individuals to project the growth of their initial investment over a specified duration. It takes into account compounding interest and enables users to assess the impact of different interest rates on their lump sum.',
    howTo: [
        'Enter initial lump sum, interest rate, and time-period.',
        'Specify compounding frequency (e.g., annually, monthly).',
        'Calculator uses formula to calculate future value.',
        'Future value is the estimated total amount after specified time, accounting for compound interest.',
    ],
    benefits: [
        'Financial Planning: Estimate the growth of a lump sum investment, set financial goals, and make informed decisions.',
        'Investment Analysis: Assess profitability and viability of investment options by comparing future values with varying rates, periods, and compounding frequencies.',
        'Accuracy and Efficiency: Perform accurate and efficient calculations, saving time and effort.',
        'Decision Making: Input scenarios, adjust variables, and make informed investment decisions while evaluating the impact of different factors on future value.',
    ]
}

export const about = [
    {
        head: 'About Devsvilla',
        content: 'This Platform is provided by DevsVilla International Pvt. Ltd that are committed to provide building high-quality products that meet the needs of our customers and help them achieve their goals. We believe in the power of innovation and collaboration, and strive to foster a culture of continuous learning and growth. DevsVilla is a global IT leader and specialized software company, providing enterprise-class IT services and product engineering services to organizations of all sizes, we partner with companies to transform and manage their business by harnessing the power of technology. We unlock our customers’ innovative potential, empowering them to turn their boldest ideas into reality and make millions of people feel like VIPs.'
    },
    {
        head: 'Software Solutions',
        content: 'At DevsVilla, we develop innovative and creative software that provide One-stop extensive solutions for cross-platform software services, Web Development, Mobile App Development, providing digital enablement, and we build World Class digital products. We believe in long-term relationships with our clients and the value of trust, commitment, and the fulfilment of goals.'
    },
    {
        head: 'Our Vision',
        content: 'We envision a future where innovation and collaboration drive the development of cutting-edge products and solutions that meet the needs of our customers and help them achieve their goals. We strive to be a trusted partner in the success of our customers, and to be recognized as a leader in the software development industry. We believe that by empowering developers to reach their full potential, we can make a positive impact on the world and build a better future for all.'
    }
]

export const termsAndConditions = {
    points: [
        "Accuracy of Information: The web app provides information on investment details and tax calculations based on the user's inputs. However, the accuracy of this information cannot be guaranteed, and users should verify the information provided by the app with a qualified financial advisor or tax professional.",
        "Limitation of Liability: The web app is not responsible for any loss or damages incurred as a result of using the app or relying on the information provided by the app.",
        "Data Privacy: The web app collects and processes user data for the purpose of calculating tax on investment gains. The app will not share or sell user data to third parties without the user's consent.",
        "Intellectual Property: All intellectual property rights, including trademarks and copyrights, associated with the web app belong to the app owner. Users are not permitted to use or reproduce the app's intellectual property without the app owner's consent.",
        "User Conduct: Users of the web app are expected to use the app in a responsible and legal manner. Users are not permitted to use the app to engage in any illegal activities or to transmit any harmful or offensive content.",
        "Modifications to the App: The web app owner reserves the right to modify or discontinue the app at any time without notice.",
        "Governing Law: These terms and conditions are governed by the laws of the jurisdiction in which the web app owner is based.",
    ]
}

export const privacyPolicy = {
    points: [
        "Thank you for visiting Fi-Enhance. The Platform is provided by DevsVilla International Pvt. Ltd. Here at “DevsVilla”, we  are committed to protecting your personal information and respecting your privacy. In general, you can visit DevsVilla on the Web without telling us who you are or revealing any information about yourself. To the extent that you do provide information to DevsVilla, DevsVilla will never sell, rent or disclose your personally identifiable information to outside parties for their own marketing purposes without your consent.\n",
        "We take the privacy of Providers very seriously. Our Privacy Policy (which may be updated from time to time) explains how we collect and use personal information that we receive through different channels, including online and offline services.\n",
        "We may collect Personal Information from Providers through online channels, which we may store electronically at our discretion for the purposes mentioned below. We do not sell or share your personal or financial information to anyone. Please note that this Privacy Policy is subject to the disclaimer on our website. However, if a Provider accesses third-party links from our website, they will not be covered by this Privacy Policy.\n",
        "We have implemented reasonable security practices and procedures to protect the Personal Information we collect. Our security measures are consistent with industry standards and include technical, operational, and physical safeguards appropriate for the sensitivity of the information.\n",
        "At our company, we place a great deal of importance on respecting and safeguarding your privacy. We believe that our relationship with you is our most valuable asset, and we want you to feel comfortable and confident when using our Platform and Services. The purpose of this Policy is to help you better understand the type of information we intend to collect from you, why we collect it, and how we use it. Additionally, this Policy covers our policies on cookies and instances of identity theft, as well as our practices regarding data security and retention. We also outline our disclaimers related to Services and the Platform, our policy regarding changes and updates to this Policy, our policy for withdrawing your consent, and your legal rights and obligations in case of disputes or grievances. Our goal is to provide you with a clear understanding of our privacy practices so that you can make informed decisions about how you share your information with us.",
    ]
}
