import { Container, Grid, Slider, Typography, TextField, Box, useMediaQuery } from '@mui/material'
import React, { useState, useEffect } from 'react'
import DonutChart from './DonutChart';
import {formatCurrencyNoDecmimal} from '../utils/FormatCurrency';

const LumpsumCalculator = () => {

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [chartData, setChartData] = useState([
    {x: 'Segment 1', y: Number(75)},
    {x: 'Segment 2', y: Number(25)},
  ]);

  const [lumpsumParameters, setLumpsumParameters] = useState([
    {name: 'Total Investment', value: 25000, min: 500, max: 1000000, step: 500},
    {name: 'Expected return rate (p.a.)', value: 12, min: 1, max: 30, step: 0.1},
    {name: 'Time Period (in years)', value: 10, min: 1, max: 40, step: 1},
  ]);

  const [principalAmount, setPrincipalAmount] = useState(0);
  const [estimatedReturn, setEstimatedReturn] = useState(0);
  const [maturityAmount, setMaturityAmount] = useState(0);

  const convertNaNToZero = value => isNaN(value) ? 0 : value;

  const handleSliderChange = (index) => (event, newValue) => {
    setLumpsumParameters((prevGains) => {
      const updatedGains = [...prevGains];
      updatedGains[index].value = newValue;
      return updatedGains;
    });
  };

  const handleTextFieldChange = (index) => (event) => {
    let newValue = parseInt(event.target.value);
    if (newValue < 0) {
      newValue = 0;
    } else if (newValue > 1000000) {
      newValue = 1000000;
    }
      setLumpsumParameters((prevGains) => {
        const updatedGains = [...prevGains];
        updatedGains[index].value = newValue;
        return updatedGains;
      });
  };

  const calculateTaxNew = () => {
    const principal = convertNaNToZero(lumpsumParameters[0].value);
    const rate = convertNaNToZero(lumpsumParameters[1].value) / 100;
    const time = convertNaNToZero(lumpsumParameters[2].value);
    const compoundingFrequency = 1;

    const futureValue =
      principal *
      Math.pow(1 + rate / compoundingFrequency, compoundingFrequency * time);
    const interest = futureValue - principal;
    const MaturityAmount = principal + interest;

    setPrincipalAmount(formatCurrencyNoDecmimal(principal));
    setEstimatedReturn(formatCurrencyNoDecmimal(interest));
    setMaturityAmount(formatCurrencyNoDecmimal(MaturityAmount));

    setChartData([
      {x: 'Interest', y: Number(interest)},
      {x: 'Principal', y: Number(principal)},
    ]);
  };

  useEffect(() => {
    calculateTaxNew();
  }, [lumpsumParameters]);

  return (
    <Container
      sx={{
        backgroundColor: '#fff',
        width: { xs: '90%', sm: '90%', md: '80%', lg: '70%' },
        borderRadius: '25px',
        marginTop: '1rem',
        marginBottom: '1rem',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
      }}
    >
      <Grid container spacing={{ xs: 1, sm: 1, md: 4, lg: 4 }} sx={{ paddingY: 2 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {lumpsumParameters.map(({ name, value, min, max, step }, index) => (
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: { xs: '0.5rem', md: '1rem' }
                }}
              >
                <Typography variant="p">{name}</Typography>
                <TextField
                  size="small"
                  type="number"
                  value={value}
                  onChange={handleTextFieldChange(index)}
                  sx={{ background: 'linear-gradient(to right, rgb(59 141 235 / 10%), rgb(177 117 248 / 10%))', border: 'none',borderRadius: '5px', textAlign: 'center', width: '110px' }}
                  inputProps={{
                    min: {min},
                    max: {max},
                    step: {step},
                  }}
                  InputProps={{ 
                    style: { color: "#B175F8" }
                  }}
                />
              </Box>
              <Slider
                value={convertNaNToZero(value)}
                onChange={handleSliderChange(index)}
                min={min}
                max={max}
                step={step}
                aria-labelledby="input-slider"
              />
            </Box>
          ))}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box height='80%'>
            <DonutChart data={chartData}/>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingX: {xs: 1, md: 6}, paddingY: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant='p'>Invested Amount</Typography>
              <Typography variant='h6' sx={{ color: '#51A2FF' }}>{principalAmount}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant='p'>Estimated Returns</Typography>
              <Typography variant='h6' sx={{ color: '#B175F8' }}>{estimatedReturn}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant={isMobile ? 'h4' : 'h2'} sx={{ textAlign: 'center', color: '#212933' }}>
            Total Value:  <span style={{ background: 'linear-gradient(to right, #3B8DEB, #B175F8)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>{maturityAmount}</span>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LumpsumCalculator;
