import React from 'react'
import { Box, Container, Typography, List, ListItem, ListItemText } from '@mui/material'
import { Navbar, Footer } from '../components'
import { privacyPolicy } from '../data'

const Policy = () => {
  return (
    <>
      <Box sx={{ backgroundImage: 'linear-gradient(to right, #3B8DEB, #B175F8)' }} >
        <Navbar />
      </Box>
      <Container>
        <Typography variant='h4'>
            Privacy Policy
        </Typography>
        <List>
          {privacyPolicy.points.map((point) => (
            <ListItem sx={{ color: '#5F6671', textAlign: 'justify' }}>
              <ListItemText primary={point} />
            </ListItem>
          ))}
        </List>
      </Container>
      <Footer />
    </>
  )
}

export default Policy