import { Container, Typography, Box, Grid, useMediaQuery } from '@mui/material'
import React from 'react';
import { mobileiOS, mobileAndroid, appleStoreDownloadButton, playStoreDownloadButton } from '../assets';

const Mobile = () => {

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Box sx={{backgroundImage: 'linear-gradient(to right, #3B8DEB, #B175F8)', my: 4, py: 8}}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
            <Typography variant={isMobile ? 'h3' : 'h2'} sx={{ marginBottom: '2rem', textAlign: 'center' }}>Fi-Enhance App</Typography>  
            <Typography variant={isMobile ? 'h2' : 'h1'}>Download</Typography>
            <Box sx={{ display: 'flex', gap: { xs: 5, md: 10 }, margin: 5, }}>
              <a href="https://apps.apple.com/in/app/fienhance/id6449054685" target='blank'>
                <img src={appleStoreDownloadButton} alt="Download on the App Store" width={ isMobile ? '150px' : '180px' } height={ isMobile ? '50px' : '60px' } />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.fienhancemobile" target='blank'>
                <img src={playStoreDownloadButton} alt="Get it on Google Play" width={ isMobile ? '150px' : '180px' } height={ isMobile ? '50px' : '60px' } />
              </a>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}>
              <Box sx={{
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                marginRight: '1rem',
                '& img': {
                  objectFit: 'contain',
                  maxWidth: '80%',
                },
              }} >
                <img src={mobileiOS} alt="Apple Phone" />
              </Box>
              <Box sx={{
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                marginRight: '1rem',
                '& img': {
                  objectFit: 'contain',
                  maxWidth: '85%',
                },
              }} >
                <img src={mobileAndroid} alt="Android Phone" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Mobile
